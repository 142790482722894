import React, { useEffect, useState } from 'react'
import NameControl from '../components/common/NameControl'
import BalanceTable from '../components/balance/BalanceTable';
import BalanceHistoryTable from '../components/balance/BalanceHistoryTable';
import SetLimit from '../components/modals/SetLimit';
import BankAccountModal from '../components/balance/modal/BankAccountModal';
import BalanceModal from '../components/balance/modal/BalanceModal';
import CurrencyModal from '../components/balance/modal/CurrencyModal';
import EnterAmount from '../components/modals/EnterAmount';
import FundBalance from '../components/modals/FundBalance';
import VirtualAccount from '../components/modals/VirtualAccount';
import CardDetails from '../components/modals/CardDetails';
import SelectPayment from '../components/modals/SelectPayment';
import TransactionOtp from '../components/modals/TransactionOtp';
import TransactionSuccessful from '../components/modals/TransactionSuccessful';
import Success from '../components/modals/Success';
import Successful from '../components/modals/Successful';
import BeneficiaryValue from '../components/balance/modal/BeneficiaryValue';
import CurrencyBalancesTransfer from '../components/balance/modal/CurrencyBalancesTransfer';
import CurrencyBalancesSuccess from '../components/balance/modal/CurrencyBalancesSuccess';
import PortFolioConfirm from '../components/balance/modal/PortFolioConfirm';
import PortfolioSuccess from '../components/balance/modal/PortfolioSuccess';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import PinOtp from '../components/modals/PinOtp';
import TableLoader from '../components/common/TableLoader';
import { DotLoader } from 'react-spinners';
import ComingSoonPop from '../components/modals/ComingSoonPop';
import ApiPayoutBalance from '../components/modals/ApiPayoutBalance';
import ApiWalletOtp from '../components/modals/ApiWalletOtp';
import VirtualConfirm from '../components/modals/VirtualConfirm';
import AmountNormal from '../components/modals/AmountNormal';
import PaymentMethod from '../components/modals/PaymentMethod';
import FundOtherBalance from '../components/modals/FundOtherBalance';
import { v4 as uuidv4 } from 'uuid';
import BankDetailModal from '../components/otc/BankDetailModal';
import ConfirmBankDetailModal from '../components/otc/ConfirmBankDetailModal';
import PayoutSumbited from '../components/otc/PayoutSumbited';
import PayoutBenSumbited from '../components/otc/PayoutBenSumbited';
import PayoutModal from '../components/otc/PayoutModal';
import PayoutBeneficiaryAdd from '../components/otc/PayoutBeneficiaryAdd';
import ConvertModal from '../components/otc/ConvertModal';
import ConfirmConvertModal from '../components/otc/ConfirmConvertModal';
import ConvertSuccess from '../components/otc/ConvertSuccess';
import FlickAccountModal from '../components/otc/FlickAccountModal';
import config from '../config.json'

function Balance() {
  useEffect(() => {
    getBalance();
    getBanks();
    getEncKey();
    getLinkedCards();
    getPermanentNuban();
    getCurrencies();
    getBeneficiary();
    getCollection();
    // eslint-disable-next-line
  }, []);
  useEffect(()=>{
    loadCountries();
 // eslint-disable-next-line
  },[])

  const { currentUser } = useSelector((state) => state?.user);
  const [btnActive,setBtnActive] = useState(false);
  let [selectedButton, setSelectedButton] = useState("All Balances");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(''); 
  const [selectedCurrency, setSelectedCurrency] = useState('NGN'); 
  const [isAmount,setIsAmount] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [isTransferPortfolioAmount,setIsTransferPortfolioAmount] = useState(false)
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [isVertual, setIsVertual] = useState(false);
  const [isCardDetails, setIsCardDetails] = useState(false);
  const [isCardMethod, setIsCardMethod] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const [isLimitSuccess, setIsLimitSuccess] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isConfirmTransfer, setIsConfirmTransfer] = useState(false);
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [isConfirmPortfolio, setIsConfirmPortfolio] = useState(false);
  const [isPortfolioSuccess, setIsPortfolioSuccess] = useState(false);
  const [isCurrency, setIsCurrency] = useState(false);
  const [formData, setFormData] = useState({
    selectedBalance: '',
    selectedCurrency: 'USD',
    amount: '',
    description: '',
  });

  const [newCurrency,setNewCurrency] = useState({
    currencyName: '',
    limitAmount:''
  })
  const [fundBalanceValues, setFundBalanceValues] = useState({
    amount:"",
    bank:"",
    card:"",
  })
  // const [transferBankInput, setTransferBankInput] = useState({
  //   balance:"",
  //   amount:"",
  //   currency: "NGN",
  //   bank_code: "",
  //   narration: "",
  //   account_number: "",
  //   beneficiary_name:"",
  // });
  const [banks,setBank] = useState([])
  const [payoutId, setPayoutId] = useState('');
  const [payoutToken,setToken] = useState('');
  const [payoutResult, setPayoutResult] = useState({})
  const [limitInputValue,setLimitInputValue] = useState("")
  const [amountInput,setAmountInput]=  useState("");
  const [isAmountLoading,SetIsAmountLoading] = useState(false);
  const [inputCardDetails,setInputCardDetails] = useState({
    name:"",
    card_number:"",
    card_date:"",
    card_cvv:"",
  
  });

  const [isLoading,setIsLoading] = useState(false);
  const [transactionId,setTransactionid] = useState('')
  const [isCardsLoading,setIsCardLoading] = useState(false);
  const [LinkedCardData,setLinkedCardData] = useState([]);
  const [livePublicEncKey,setLivePublicEncKey] = useState("");
  const [isPinOtp,setIsPinOtp] = useState(false);
  const [isOtpLoading,setIsOtpLoading] = useState(false)
  const [isPinLoading,setIsPinLoading] = useState(false)
  // const [isLoading,setIsLoading] = useState(false);
  const [balanceData,setBalanceData] = useState([])

  const [isBankLoading,setIsBankLoading] = useState(false)
  const [isApiWallet,setIsApiWallet] = useState(false)
  const [nubanData,setNubanData] = useState([]);
  const [nubanTempData,setNubanTempData] = useState({});
  const [isCommingSoon,setIsCommingSoon] = useState(false);
  const [payoutCurrency,setPayoutCurrency] = useState('')
  const [balanceHistoryData,setBalanceHistoryData] = useState([])
  const [isApiPayout,setIsApiPayout] = useState(false)
  const [apiWallet,setApiWallet] = useState('')
  const [isApiFundLoading,setIsApiFundLoading] = useState(false)
  const [isApiOtp,setIsApiOtp] = useState(false)
  const [apiData,setApiData] = useState({})
  const [apiInputData,setApiInputData] = useState({})
  const [isVirtualConfirm,setIsVirtualConfirm] = useState(false)
  const [nubanId,setNubanId] = useState('')
  const [nubTemInput,setNubTempInput] = useState({});
  const [isAmountNormal,setIsAmountNormal] = useState(false)
  const [isPayment,setIsPayment] = useState(false)
  const [isOtherBalance,setIsOtherBalance] = useState(false)
  const [currencyList,setCurrencyList] = useState([])
  // const [apiCurrency,setApiCurrency] = useState('')




  // =========== payout State =================

  const [isPayout,setIsPayout] = useState(false)
  const [isBankDetial,setIsBankDetail] = useState(false)
  const [isConfirmBankDetial,setIsConfirmBankDetial] = useState(false)
  const [isPayoutSubmitted,setIsPayoutSubmited] = useState(false)
  const [isPayoutBenSubmitted,setIsPayoutBenSubmited] = useState(false)
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({}); 
  const [beneficiaryData,setBeneficiaryData] = useState([]);
  const [isBenAdd,setIsBenAdd] = useState(false);
  const [isAddBeneficiary,setIsAddBeneficiary] = useState(false)
  const [isGetBeneficiary,setIsGetBeneficiary] = useState(false)
  const [countries, setCountries] = useState([]);
  const [isFxPayoutLoading,setIsFxPayoutLoading] = useState(false)
  const [fxAmount,setFxAmount] = useState('')
  const [collectionData,setCollectionData] = useState([]);

  // =========== Convert States =================

  const [rateMsg,setRateMsg] = useState('')
  const [isRateLoading, setIsRateLoading] = useState(false);
  const [initiatData,setInitiateData] = useState({});
  const [rateInitial,setRateInitial] = useState({})
  const [isConvert,setIsConvert] = useState(false)
  const [isConfirmConvert,setConfirmConvert] = useState(false)
  const [isConvertSuccess,setIsConvertSuccess] = useState(false)
  const [convertInput,setConvertInput] = useState({
    
      from_currency:"NGN",
      from_currency_amount:"",
      to_currency:"USD",
      to_currency_amount:""
  });
  const [rateInfo, setRateInfo] = useState(null);



  // ========= Payout Input =============

  const excludedCountries = ['Cuba', 'Iran', 'North Korea', 'Syria', 'Russia', 'Belarus', 'Ukraine','United Kingdom','United States','China'];
  const [beneficiaryInput, setBeneficiaryInput] = useState({
    account_no:"",
    routing:"",
    beneficiary_name:"",
    beneficiary_address_1:"",
    beneficiary_city:"",
    beneficiary_state:"",
    beneficiary_country:"United States",
    beneficiary_postal_code:"",
    bank_name:"Wells Fargo",
    bank_address_1:"",
    bank_address_2:"",
    bank_city:"",
    bank_state:"",
    bank_country:"United States",
    bank_postal_code:""
  })

  const [payoutInput,setPayoutInput] = useState({
    wireType:"",
    accountNumber:"",
    routingNumber:"",
    beneficiaryAccName:"",
    address1:"",
    address2:"",
    city:"",
    state:"",
    country:"",
    postalCode:"",
    currency:""
});
const [bankDetailInput,setBankDetailInput] = useState({
  bankName:"",
  address1:"",
  address2:"",
  city:"",
  state:"",
  country:"",
  postalCode:""
});

const [walletBeneficiaryInput,setWalletBeneficiaryInput] = useState({
  wireType:"",
  accountNumber:"",
  routingNumber:"",
  beneficiaryAccName:"",
  address1:"",
  address2:"",
  city:"",
  state:"",
  country:"",
  postalCode:""
});
const [transferBankInput, setTransferBankInput] = useState({
  balance:"NGN",
  amount:"",
  currency: "NGN",
  bank_code: "",
  narration: "",
  account_number: "",
  beneficiary_name:"",
});
const [flickPayoutInput, setFlickPayoutInput] = useState({
  currency: "NGN",
  bank_code: "P2P",
  beneBusinessCode: "",
  amount: "",
  narration: "",
  beneficiary_name: ""

});

// ======= end Payout=========

  const toggleModal = () => {
    setIsPayout(!isPayout)
    // setIsOpenPopup(!isOpenPopup);
  };

  // ==== convert toggle ======
  const toggleConvert = ()=>{
    setIsConvert(!isConvert)
}
    
    
  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const toggleSetLimitModal = () => {
    setIsModalOpen(!isModalOpen)
  };

   
   
  const Transferbetween = ()=>{
    // console.log("from parent: ",formData)
  }

  //collection
  const getCollection = async (currency="NGN")=>{
    try{
     
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        "sweetapi": currentUser?.user?.merchantCode  
      }

      const data = {
        category: "all", //required  
        currency: currency, // required
        limit:200 // optional
      }
      
      // const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions';
      const url =
      ` ${config.apiEndpoint}/transactions`;
      let res = await axios.post(url,{...data},{
        headers:headers
      })
    
      const newValue = res?.data?.data
     
      setCollectionData(newValue);
   
      
      // toast.success(res.data.message);
    }catch(err){
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error"
    }
  }


  const AddCurrency = async()=>{

    try {

      const headers = {
        api_key: currentUser?.user?.merchantKey,
        'Content-Type': 'application/json'
      };

      // console.log("currency: ",newCurrency.currencyName)

      const data = {
        currency:newCurrency.currencyName
      }

     
      const url = "https://flick-app.com/merchant/add-currency";
      let res = await axios.post(url,{...data},{ headers: headers});
      // console.log(res.data)
      getBalance();
      setIsCurrency(false);
      toast.success('Currency added Successfully')
      // console.log("new currency response",res.data)

    } catch (err) {
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }

    
  }
  const confirmLimit =()=>{
    // console.log("limit value : ",limitInputValue)
  }
  const renderContent = () => {
    if(selectedButton === 'All Balances') return <BalanceTable
     openSetLimitModal={toggleSetLimitModal} 
     setIsAmount = {setIsAmount}
     setIsModalOpen = {setIsModalOpen}
     setIsPaymentMethod={setIsPaymentMethod}
     balanceData={balanceData}  
     isBankLoading={isBankLoading}
     setIsApiWallet={setIsApiWallet}
     setIsCommingSoon={setIsCommingSoon}
     setPayoutCurrency={setPayoutCurrency}
     setApiWallet={setApiWallet}
     setIsPayment={setIsPayment}
     />;
    if(selectedButton ==='Balance History') return <BalanceHistoryTable 
      balanceHistoryData={balanceHistoryData}
      balanceData={balanceData}  
      getCollection={getCollection}
      selectedCurrency={selectedCurrency}
      setSelectedCurrency={setSelectedCurrency}
      collectionData={collectionData}
    
    />;
  };

  // Modal inside Select Transfer options
  const renderModal = () => {
    switch (selectedOption) {
      case 'Transfer to Bank Account':
        return <PayoutModal 
        setIsPayout={setIsPayout} 
        payoutInput={payoutInput} 
        setPayoutInput={setPayoutInput} 
        setIsBankDetail={setIsBankDetail}
        transferBankInput={transferBankInput}
        setTransferBankInput={setTransferBankInput}
        setIsBeneficiary={setIsBeneficiary}
        setIsConfirmPortfolio={setIsConfirmPortfolio}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        balanceData={balanceData}
        banks={banks}
        setPayoutId={setPayoutId}
        setPayoutResult={setPayoutResult}
        setSelectedBeneficiary={setSelectedBeneficiary}
        setIsConfirmBankDetial={setIsConfirmBankDetial}
        setIsBenAdd={setIsBenAdd}
        addBeneficiary={addBeneficiary}
        beneficiaryData={beneficiaryData}
        countries={countries}
        setFxAmount ={setFxAmount}
        
      />
      case 'Transfer to Flick Account':
        return <FlickAccountModal 
        setIsPayout={setIsPayout} 
        flickPayoutInput={flickPayoutInput} 
        setFlickPayoutInput={setFlickPayoutInput} 
       
       
        setIsBeneficiary={setIsBeneficiary}
        setIsConfirmPortfolio={setIsConfirmPortfolio}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        balanceData={balanceData}
        banks={banks}
        setPayoutId={setPayoutId}
        setPayoutResult={setPayoutResult}
        setSelectedBeneficiary={setSelectedBeneficiary}
        setIsConfirmBankDetial={setIsConfirmBankDetial}
        setIsBenAdd={setIsBenAdd}
        addBeneficiary={addBeneficiary}
        beneficiaryData={beneficiaryData}
        countries={countries}
        setFxAmount ={setFxAmount}
        
      />
        
      case 'Transfer Between Currency Accounts':
        return<ConvertModal  
        setIsPayout={setIsPayout} 
        convertInput={convertInput} 
        setConvertInput={setConvertInput} 
        setConfirmConvert={setConfirmConvert} 
        rateMsg={rateMsg}
        setRateMsg={setRateMsg}
        setInitiateData={setInitiateData}
        isRateLoading={isRateLoading}
        setIsRateLoading={setIsRateLoading}
        getExchange={getExchange}
        countries={countries}
        rateInfo={rateInfo}
        rateInitial={rateInitial}
        />
     
      default:
        return null; // No modal to display
    }
  };

  const getBalance = async ()=>{
    setIsBankLoading(true)
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const url = `${config.apiEndpoint}/balances`;
      let res = await axios.get(url,{
        headers:headers
      });
      const newValue = res.data.data
     
      setBalanceData(newValue);
     
      setIsBankLoading(false)
       
    }catch(err){
      setIsBankLoading(false)
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }
  

  const getBanks = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      // const data = {
      //   category: "payout", //required
      //   currency: "NGN", // required
      //   limit: 5, // optional
      // };

      const url =
        `${config.apiEndpoint}/banks`;
      let res = await axios.get(url,{ headers: headers});
      setBank(res?.data?.data)
      
    } catch (err) {
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }
  };



  const addLimit = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      // const data = {
      //   category: "payout", //required
      //   currency: "NGN", // required
      //   limit: 5, // optional
      // };

      const data = {
        lowLimit:`${Number(limitInputValue) * 100}`
      }
      
      const url =
       ` ${config.apiEndpoint}/set-low-limit`;
      let res = await axios.post(url,{...data},{ headers: headers});
      console.log("response",res.data)
      setIsLimitSuccess(true)
      
    } catch (err) {
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }
  };

  const iniateCharge = async (e)=>{
      let amount
      SetIsAmountLoading(true)
      try{

          const headers = {  
              Authorization:`Bearer ${currentUser?.user?.merchantKey}`
          }
         
          amount = {
            amount: `${amountInput * 100}`,
            url:"https://checkout.paywithflick.co/pages/"
          }
          
          // const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge"
          const url =
          ` ${config.apiEndpointNuban}/create-charge`;
          let res = await axios.post(url,amount,{
            headers:headers
          });
          
          
          const transId = res?.data?.data?.transactionId || res.data?.transactionId
          setTransactionid(transId);
          SetIsAmountLoading(false);
          setIsPaymentMethod(true);
          
      }catch(err){
          SetIsAmountLoading(false)
          console.log(err);
          const errorMessage = err.response.data?.message;
          toast.error(errorMessage);

      }

  }


  const iniateChargeOtherBalance = async (e)=>{
    let amount
    SetIsAmountLoading(true)
    try{

        const headers = {  
            Authorization:`Bearer ${currentUser?.user?.merchantKey}`
        }
       
        amount = {
          amount: `${amountInput * 100}`,
          url:"https://checkout.paywithflick.co/pages/"
        }
        
        // const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge"
        const url =
        ` ${config.apiEndpointNuban}/create-charge`;
        let res = await axios.post(url,amount,{
          headers:headers
        });
        
        const transId = res?.data?.data?.transactionId || res.data?.transactionId
        setTransactionid(transId);
        SetIsAmountLoading(false);
        setIsOtherBalance(true);
        
    }catch(err){
        SetIsAmountLoading(false)
        console.log(err)
    }

  }

  const getLinkedCards = async ()=>{
    
    try{
      
        const headers = {
          api_key: currentUser?.user?.merchantKey,
          sweetapi: currentUser?.user?.merchantCode,
        };

       
        const url =
        ` ${config.apiEndpoint}/get-all-payment-card`;
        let res = await axios.get(url,{
          headers:headers
        });
       
        const data = res?.data?.data
        setLinkedCardData(data);    
    }catch(err){
        console.log(err)
    }
  }


  const getEncKey = async ()=>{
    
    try{

        const headers = {
          api_key: currentUser?.user?.merchantKey,
          sweetapi: currentUser?.user?.merchantCode,
        };

        // const url ="https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-encryption-key"
        const url =
        ` ${config.apiEndpoint}/get-encryption-key`;
        let res = await axios.get(url,{
          headers:headers
        });
        
        const data = res?.data?.data?.livePublicEncKey
        setLivePublicEncKey(data)
    }catch(err){
        console.log(err)
    }
  }

  // FundApi wallet via other Balance

  const handleApiOtherBalance = async (currency)=>{
   
    setIsApiFundLoading(true);
    try{
        const headers = {
            'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
        };
        const data = {
            amount:  `${amountInput * 100}`,
            currency: currency || "NGN",
            bank_code: "apiwallet",
            currency_settled: currency || "NGN",
        }
       
      // Make an API call to fetch accountName using bankCode and accountNumber.
        
        setApiInputData(data)
        // const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
       
        const url =
        ` ${config.apiEndpointNuban}/payout`;
        let res = await axios.post(url,{...data},{ headers: headers});
        
        setApiData(res.data)
        setIsApiFundLoading(false);
        setIsApiOtp(true)
        
    }catch(err){
      setIsApiFundLoading(false);
      console.log(err)
    }
    
  }

  
  const getPermanentNuban = async ()=>{
   
  
    const id = uuidv4(); 
    try{
      const headers = {  
        Authorization:`Bearer ${currentUser?.user?.merchantKey}`
        }
       
        const payLoad = { 
            transactionId: id,
            nubanType: "business"
        }
       
      // Make an API call to fetch accountName using bankCode and accountNumber.
        // const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-create-merchant"
        const url =
        ` ${config.apiEndpointNuban}/nuban-create-merchant`;
        let res = await axios.post(url,payLoad,{
          headers:headers
        });
        // console.log("nuban Data: ",res.data);
        setNubanData(res.data.data);
     
    }catch(err){
      console.log(err)
    }
    
  }


  const getCurrencies = async ()=>{
    
    try{
      
        const headers = {
          api_key: currentUser?.user?.merchantKey,
        };

        const url ="https://flick-app.com/merchant/list-currency"
        let res = await axios.get(url,{
          headers:headers
        });
       
        const data = res?.data?.data
        console.log("currency: ", data)
        setCurrencyList(res.data?.data);
        // console.log("currency List",res.data)   
    }catch(err){
        console.log(err)
    }
  }



  //=========== Payout functions=========

const loadCountries = () => {
  axios.get('https://api.countrystatecity.in/v1/countries', {
      headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
  })
  .then(response => {
      const filteredCountries = response.data.filter(country => 
          !excludedCountries.includes(country.name)
      );
      setCountries(filteredCountries);
  })
  .catch(error => console.error('Error loading countries:', error));
};

useEffect(() => {
  getExchange(convertInput.from_currency, convertInput.to_currency);
  // eslint-disable-next-line
}, []);

// 

const getBeneficiary = async () => {
  setIsGetBeneficiary(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }
      
    const url = "https://flick-app.com/merchant/get-beneficiary";
  
    let res = await axios.get(url, { headers });
    // console.log("beneficiarys: ",res.data.data)
    setBeneficiaryData(res.data.data);
    setIsGetBeneficiary(false);
  } catch (err) {
    setIsGetBeneficiary(false);
    console.log(err);
    const errorMessage = err?.response?.data?.message || "Network error";
    // toast.error(errorMessage);
  } finally {
    
    setIsGetBeneficiary(false);
  }
};

const addBeneficiary = async () => {
  setIsAddBeneficiary(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }

    const data = {
      account_no:beneficiaryInput.account_no,
      routing:beneficiaryInput.routing,
      beneficiary_name:beneficiaryInput.beneficiary_name,
      beneficiary_address_1:beneficiaryInput.beneficiary_address_1,
      beneficiary_city:beneficiaryInput.beneficiary_city,
      beneficiary_state:beneficiaryInput.beneficiary_state,
      beneficiary_country:beneficiaryInput.beneficiary_country,
      beneficiary_postal_code:beneficiaryInput.beneficiary_postal_code,
      bank_name:beneficiaryInput.bank_name,
      bank_address_1:beneficiaryInput.bank_address_1,
      bank_address_2:beneficiaryInput.bank_address_2,
      bank_city:beneficiaryInput.bank_city,
      bank_state:beneficiaryInput.bank_state,
      bank_country:beneficiaryInput.bank_country,
      bank_postal_code:beneficiaryInput.bank_postal_code
    }

    
    // console.log("allData",data);
      
    const url = "https://flick-app.com/merchant/save-beneficiary"
    // console.log("url :",url)

    
    let res = await axios.post(url,{...data},{
      headers:headers
    })

    // console.log("response: ",res.data)
    
    // toast.success(res.data?.message);
    getBeneficiary();
    // setIsAddBeneficiary(false);
 

    // setIsAddBeneficiary(false);
  } catch (err) {
    setIsAddBeneficiary(false);
    console.log(err);
    const errorMessage = err?.response?.data?.message ;
    // toast.error(errorMessage,{ position: "top-left", pauseOnHover: true});
  } finally {
    setIsAddBeneficiary(false);
  }
};

const fxPayout = async () => {
  setIsFxPayoutLoading(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }
    // console.log("selected ben: ",selectedBeneficiary);

    const data = {
      from_currency: transferBankInput.currency,
      benficiary_id: selectedBeneficiary.beneficiary_id,
      amount: fxAmount
    }

   

    // console.log("input data: ",data)
    const url = "https://flick-app.com/merchant/complete-payout"
    
    let res = await axios.post(url,{...data},{
      headers:headers
    })
    // console.log("response: ",res.data)
    setIsFxPayoutLoading(false)
    setIsPayoutSubmited(true);
  } catch (err) {
    setIsFxPayoutLoading(false);
    console.log(err)
    const errorMessage = err.response?.data?.message || "An error has occured"   
    toast.error(errorMessage,{position: "top-left"})
  } finally {
    setIsFxPayoutLoading(false);
  }
};


// ========== End of Payout =============



// =========== Convert functions ===========

const getExchange = async (fromCurrency, toCurrency) => {
  setIsRateLoading(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }
      
    const url = `https://flick-app.com/otc-dash/exchange_rate?from_currency=NGN&to_currency=${toCurrency}`;
    // console.log("url :",url)
    let res = await axios.get(url, { headers });
    
    setRateInitial(res.data.data)
    setRateInfo(res.data?.data);
    setRateMsg(res.data.data?.rate);
  } catch (err) {
    console.log(err);
    const errorMessage = err?.response?.data?.data?.message || err?.response?.data?.message || "error has occured" 
    // toast.error(errorMessage,{ position: "top-left", pauseOnHover: true})
  } finally {
    setIsRateLoading(false);
  }
};




  return (
    
      <div className='main-inner'>
          
          <NameControl 
          balance
          addCurr
          name={selectedButton}
          desc="The balance is shown here"
          btnFirst="All Balances"
          btnSecond="Balance History"
          btnActive={btnActive}
          handleButtonClick ={handleButtonClick}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          selectedCurrency = {selectedCurrency}
          setSelectedCurrency = {setSelectedCurrency}
          toggleModal={toggleModal}
          setIsOpenPopup={setIsOpenPopup}
          setIsPayout={setIsPayout}
          setIsCurrency={setIsCurrency}
          balanceData={balanceData}
          getCollection={getCollection}
          />
          
          {renderContent()}
          

          {isModalOpen && <SetLimit 
          open={true} onClose={toggleSetLimitModal}
          title="Set low limit"
          desc="Enter a minimum threshold to be notified"
          setIsModalOpen ={setIsModalOpen}
          setLimitInputValue={setLimitInputValue}
          limitInputValue ={limitInputValue}
          confirmLimit={confirmLimit}
          setIsLimitSuccess={setIsLimitSuccess}
          addLimit={addLimit}
          />}
          {isPayout && renderModal()}

          {/*======== Payout modals ====== */}

          {  isBenAdd && <PayoutBeneficiaryAdd  setIsBenAdd={setIsBenAdd} setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial}  beneficiaryInput={beneficiaryInput} setBeneficiaryInput={setBeneficiaryInput} countries={countries}/>}
          {  isBankDetial && <BankDetailModal setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsBenAdd={setIsBenAdd} setBeneficiaryInput={setBeneficiaryInput} addBeneficiary={addBeneficiary} isAddBeneficiary={isAddBeneficiary} beneficiaryInput={beneficiaryInput} countries={countries} setIsPayoutBenSubmited={setIsPayoutBenSubmited}/>}
          {  isConfirmBankDetial && <ConfirmBankDetailModal setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} selectedBeneficiary={selectedBeneficiary} fxPayout={fxPayout} isFxPayoutLoading={isFxPayoutLoading} transferBankInput={transferBankInput}/>}
          {  isPayoutSubmitted && <PayoutSumbited setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} />}
          {  isPayoutBenSubmitted && <PayoutBenSumbited setIsPayout={setIsPayout} setIsBenAdd={setIsBenAdd}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutBenSubmited={setIsPayoutBenSubmited} />}



            {/* ====== Convert Modal ====== */}

            
              
          {isConfirmConvert && <ConfirmConvertModal  setConfirmConvert={setConfirmConvert} setIsConvertSuccess = {setIsConvertSuccess}  setIsConvert={setIsConvert} setIsPayout={setIsPayout} initiatData={initiatData} getBalance={getBalance} convertInput={convertInput}/>  }
          {isConvertSuccess && <ConvertSuccess setConfirmConvert={setConfirmConvert} setIsConvertSuccess = {setIsConvertSuccess}  setIsConvert={setIsConvert} initiatData={initiatData} setIsPayout={setIsPayout}/>}

          {isLimitSuccess && <Successful
          setIsLimitSuccess={setIsLimitSuccess}
          setIsModalOpen ={setIsModalOpen}
          limitInputValue ={limitInputValue}
          />}
          
          {isAmount && <EnterAmount 
          setIsAmount = {setIsAmount}
          setIsPaymentMethod ={setIsPaymentMethod}
          setAmountInput={setAmountInput}
          iniateCharge={iniateCharge}
          isAmountLoading = {isAmountLoading}
          isApiWallet = {isApiWallet}
          setApiWallet={setApiWallet}
          />}

         {
            isPayment &&
            <PaymentMethod
              setIsPayment = {setIsPayment}
              setIsAmountNormal = {setIsAmountNormal}
              setIsAmount = {setIsAmount}
              setIsVertual ={setIsVertual}
              setIsCardMethod={setIsCardMethod}
              getLinkedCards={getLinkedCards}
              transactionId={transactionId}
              setNubanData={setNubanData}
              nubanData={nubanData}
              setIsCommingSoon={setIsCommingSoon}
              payoutCurrency={payoutCurrency}
              apiWallet = {apiWallet}
              setApiWallet ={setApiWallet}
              setIsApiPayout={setIsApiPayout}
              amountInput={amountInput}
              setNubanTempData={setNubanTempData}
              setIsVirtualConfirm = {setIsVirtualConfirm}
              setNubanId = {setNubanId}
              setNubTempInput={setNubTempInput}
              setIsLoading={setIsLoading}
            />
          }
          {isPaymentMethod && <FundBalance 
          setIsPaymentMethod={setIsPaymentMethod} 
          setIsAmount = {setIsAmount}
          setIsVertual ={setIsVertual}
          setIsCardMethod={setIsCardMethod}
          getLinkedCards={getLinkedCards}
          transactionId={transactionId}
          setNubanData={setNubanData}
          setIsCommingSoon={setIsCommingSoon}
          payoutCurrency={payoutCurrency}
          apiWallet = {apiWallet}
          setApiWallet ={setApiWallet}
          setIsApiPayout={setIsApiPayout}
          amountInput={amountInput}
          setNubanTempData={setNubanTempData}
          setIsVirtualConfirm = {setIsVirtualConfirm}
          setNubanId = {setNubanId}
          setNubTempInput={setNubTempInput}
          />}

          

          {
            isAmountNormal &&
            <AmountNormal 
            setIsAmountNormal = {setIsAmountNormal}
            setIsOtherBalance ={setIsOtherBalance}
            setIsPaymentMethod ={setIsPaymentMethod}
            setAmountInput={setAmountInput}
            iniateCharge={iniateCharge}
            iniateChargeOtherBalance={iniateChargeOtherBalance}
            isAmountLoading = {isAmountLoading}
            isApiWallet = {isApiWallet}
            setApiWallet={setApiWallet}
            setIsPayment = {setIsPayment}
            
            />
          }
          {
            isOtherBalance &&
            <FundOtherBalance 
            setIsOtherBalance ={setIsOtherBalance}
            setIsAmount = {setIsAmount}
            setIsAmountNormal={setIsAmountNormal}
            setIsVertual ={setIsVertual}
            setIsCardMethod={setIsCardMethod}
            getLinkedCards={getLinkedCards}
            transactionId={transactionId}
            setNubanData={setNubanData}
            setIsCommingSoon={setIsCommingSoon}
            payoutCurrency={payoutCurrency}
            apiWallet = {apiWallet}
            setApiWallet ={setApiWallet}
            setIsApiPayout={setIsApiPayout}
            amountInput={amountInput}
            setNubanTempData={setNubanTempData}
            setIsVirtualConfirm = {setIsVirtualConfirm}
            setNubanId = {setNubanId}
            setNubTempInput={setNubTempInput}
            setIsPayment = {setIsPayment}
            />
          }

          {
            isApiPayout && <ApiPayoutBalance 
            setIsApiPayout={setIsApiPayout}
            balanceData={balanceData}
            handleApiOtherBalance={handleApiOtherBalance}
            isApiFundLoading={isApiFundLoading}
            />
          }

          { 
          isApiOtp &&
            <ApiWalletOtp 
            setIsApiOtp={setIsApiOtp}
            setIsApiPayout={setIsApiPayout}
            apiData ={apiData}
            apiInputData={apiInputData}
            setIsPaymentMethod={setIsPaymentMethod}
            setIsAmount = {setIsAmount} 
            getBalance ={getBalance}
            />
          }
          
          {isVertual && 
          <VirtualAccount
          setIsPaymentMethod={setIsPaymentMethod} 
          setIsVertual ={setIsVertual}
          setIsSuccess = {setIsSuccess}
          transactionId={transactionId}
          nubanData={nubanData}
          apiWallet={apiWallet}
          nubanTempData={nubanTempData}
          setIsVirtualConfirm = {setIsVirtualConfirm}
          amountInput={amountInput}
          setIsPayment={setIsPayment}
          />}
          {
            isVirtualConfirm &&
            <VirtualConfirm 
            setIsVirtualConfirm = {setIsVirtualConfirm}
            isVirtualConfirm = {isVirtualConfirm}
            transactionId = {nubanId}
            nubTemInput={nubTemInput}
            getBalance={getBalance}
            />
          }
          {isCardMethod && <SelectPayment 
           setIsCardMethod={setIsCardMethod}
           setIsOtp={setIsOtp}
           setIsCardDetails={setIsCardDetails}
           LinkedCardData ={LinkedCardData}
           setIsPinOtp={setIsPinOtp}
           transactionId={transactionId}
           apiWallet = {apiWallet}
           setIsAmountNormal={setIsAmountNormal}
           setIsPayment = {setIsPayment}
           setIsOtherBalance ={setIsOtherBalance}
           />}
           {isCardDetails && <CardDetails 
             setIsCardDetails={setIsCardDetails}
             setIsCardMethod ={setIsCardMethod}
             transactionId={transactionId}
             livePublicEncKey={livePublicEncKey}
             setIsOtp={setIsOtp}
             setIsPinOtp={setIsPinOtp}
             inputCardDetails={inputCardDetails}
             setInputCardDetails ={setInputCardDetails}
             setIsCardLoading={setIsCardLoading}
             apiWallet = {apiWallet}
             setIsAmountNormal={setIsAmountNormal}
             setIsPayment = {setIsPayment}
             setIsOtherBalance ={setIsOtherBalance}
           />}
          {isOtp && <TransactionOtp 
            setIsPinLoading = {setIsPinLoading}
           setIsOtp={setIsOtp}
           transactionId={transactionId}
           setIsCardMethod={setIsCardMethod}
           setIsTransactionSuccess={setIsTransactionSuccess}
           setIsPinOtp={setIsPinOtp}
           setIsAmountNormal={setIsAmountNormal}
            setIsPayment = {setIsPayment}
            setIsOtherBalance ={setIsOtherBalance}
            setIsCardDetails={setIsCardDetails}
          />}

          {
            isPinOtp && <PinOtp 
            setIsPinOtp={setIsPinOtp}
            setIsOtpLoading={setIsOtpLoading}
            setIsOtp={setIsOtp}
            setIsCardMethod={setIsCardMethod}
            setIsTransactionSuccess={setIsTransactionSuccess}
            transactionId={transactionId}
            getBalance={getBalance}
            setIsAmountNormal={setIsAmountNormal}
            setIsPayment = {setIsPayment}
            setIsOtherBalance ={setIsOtherBalance}
            setIsCardDetails={setIsCardDetails}
            
            />
          }
          {isTransactionSuccess && <TransactionSuccessful 
           setIsTransactionSuccess={setIsTransactionSuccess}
           inputCardDetails={inputCardDetails}
           amountInput={amountInput}
           transactionId = {transactionId}
           setIsAmount = {setIsAmount}
           setIsPaymentMethod={setIsPaymentMethod}
           setIsCardMethod={setIsCardMethod}
           setIsCardDetails={setIsCardDetails}
           setIsOtp={setIsOtp}
           setIsPinOtp={setIsPinOtp}
           getBalance={getBalance}
           setIsAmountNormal={setIsAmountNormal}
            setIsPayment = {setIsPayment}
            setIsOtherBalance ={setIsOtherBalance}
            
          />}
          {isConfirmTransfer && <CurrencyBalancesTransfer
           setIsConfirmTransfer={setIsConfirmTransfer}
           setIsTransferSuccess= {setIsTransferSuccess}
           setIsOpenPopup={setIsOpenPopup}
           setIsPayout={setIsPayout}
          />}
          {isTransferSuccess && <CurrencyBalancesSuccess
           setIsTransferSuccess= {setIsTransferSuccess}
           setIsConfirmTransfer={setIsConfirmTransfer}
           setIsOpenPopup={setIsOpenPopup}
           setIsPayout={setIsPayout}
          />}
          {isSuccess && <Success 
           setIsTransactionSuccess={setIsTransactionSuccess}
           setIsSuccess= {setIsSuccess}
          />}
          {isCurrency && <CurrencyModal 
          setIsCurrency = {setIsCurrency}
          setIsOpenPopup={setIsOpenPopup} 
          setIsPayout={setIsPayout}
          newCurrency={newCurrency}
          setNewCurrency={setNewCurrency}
          AddCurrency = {AddCurrency}
          currencyList={currencyList}
        />}
        {
          isBeneficiary && <BeneficiaryValue 
          setIsBeneficiary={setIsBeneficiary}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsOpenPopup={setIsOpenPopup}
          setIsPayout={setIsPayout}
        />}
      {isConfirmPortfolio && (
        <PortFolioConfirm
        setIsBeneficiary={setIsBeneficiary}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
        setIsConfirmPortfolio={setIsConfirmPortfolio}
        setIsTransactionSuccess={setIsTransactionSuccess}
        setIsOpenPopup={setIsOpenPopup}
        setIsPayout={setIsPayout}
        setToken={setToken}
        payoutId={payoutId}
        payoutToken={payoutToken}
        transferBankInput={transferBankInput}
        payoutResult ={payoutResult}
      />
      )}
      {isPortfolioSuccess && (
        <PortfolioSuccess
        setIsTransactionSuccess={setIsTransactionSuccess}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
        setIsConfirmPortfolio={setIsConfirmPortfolio}
        setIsOpenPopup={setIsOpenPopup}
        setIsPayout={setIsPayout}
        transferBankInput={transferBankInput}
        payoutResult ={payoutResult}
        />
      )}

      {
        isCommingSoon &&
        <ComingSoonPop setIsCommingSoon={setIsCommingSoon}/>
      }

      {
        (isAmountLoading || isCardsLoading || isOtpLoading || isPinLoading || isLoading) &&
        <TableLoader msg />
      }
      </div>
       
  )
}

export default Balance
