import React from "react";
import './Modal.scss'
import { BeatLoader } from "react-spinners";

function EnterAmount({setIsAmount,setAmountInput,iniateCharge,isAmountLoading,balance,isApiWallet,setApiWallet}) {
//   if (!open) return null;

  const handleChange = (e)=>{
    setAmountInput(e.target.value);
  }
 
  const handleClick = ()=>{
    iniateCharge();
  }
  return (
    <div className="overlay">
    <div className="enter-amount-modal">
      <div className="modal-wrapper">

        <div>
        <img style={{cursor:"pointer"}} onClick={setIsAmount.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Enter amount</p>
          
          <p className="enter-an-amount">Enter an amount to be funded into your account</p>
        </div>

      <div className="input-amount">
        <img src={process.env.PUBLIC_URL + "/images/amount.svg"} alt="amount" />
        <input
          type="text"
          name="amount"
          placeholder="2,000.00"
          className="amount-input"
          onChange={handleChange}
        />
      </div>

        <div className="modal-btn">
          <button onClick={setIsAmount.bind(this,false)} className="sec-btn">
            Cancel
          </button>
          {/* {
            isAmountLoading ?
            <button  className="btn"> 
                <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                />                
            </button>:
          } */}
          <button onClick={handleClick} className="btn">
            Confirm
          </button>
        </div>
        </div>
    </div>
    </div>
  );
}

export default EnterAmount;
