import React, { useRef, useState } from 'react'
import SideBarData  from './SideBarData.json'
import SidebarSubMenu from './SideBarMenu';
import { useDispatch, useSelector } from 'react-redux';
import './Sidebar.scss'
import { logout } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';
import NewBusinessModal from './NewBusinessModal';
import { copyToClipboard } from '../utilities/clipboardUtils';
import { FaTimes } from "react-icons/fa";
import SideBarMobileMenu from './SideBarMobileMenu';

function SideBarMobile({avatar,name,email, mode, MobileMenuHandler,show}) {
    const {currentUser} = useSelector((state)=>state?.user)
    const allSideBarData = SideBarData;
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [showLogout, setShowLogout] = useState(false);
    const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);
    const refLink = useRef(null);
  
  
  

    const handleSubMenuClick = (index) => {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleAgreement = () => {
      navigate('/agreement')
      MobileMenuHandler();
    }
    const handleLogOut = () =>{
      dispatch(logout())
      navigate('/')
      MobileMenuHandler();
    }
    const toggleShowLogout = () => {
      setShowLogout(!showLogout);
    };
    const navigateHome = ()=>{
      navigate('/')
      MobileMenuHandler();
    }
    const handleNewBusinessClick = () => {
      setShowNewBusinessModal(true);
      MobileMenuHandler();
    };
  return (
    <div className={`sideBarOverly ${show ? 'show':''}`}>
        <div className='sideBar_mobile'>
        <div className='sideBarWrapper'>
            <div className='sidebar-menu-wrapper'>
            <div className='header_logos mobile_logo'>
                <img onClick={navigateHome} className='sidebar-logo ' src={process.env.PUBLIC_URL+"/images/logoSidbar.svg"} alt="nav" />
                <button className="mobile-menu-close" onClick={MobileMenuHandler} ><FaTimes /></button>
            </div>
            <div className='sidebar-content-container'>
                <h1 className='main_menu_text'>MAIN MENU</h1> 
                {
                      currentUser ? (
                        allSideBarData.filter((item, index) => {
                          const { user } = currentUser;

                          // Default to false if any parameter is missing
                          const is_otc = user.is_otc ?? false;
                          const is_data = user.is_data ?? false;
                          const is_identity_only = user.is_identity_only ?? false;
                          const is_payment = user.is_payment ?? false;

                          // Check if only is_otc is true and all others are false
                          const onlyOtcTrue = is_otc && !is_data && !is_identity_only && !is_payment;

                          // Check if all specific user parameters are false or undefined
                          const useDefaultItems = !is_otc && !is_data && !is_identity_only && !is_payment;

                          // Items to always include
                          const alwaysInclude = ["Balance", "Get started", "Overview", "Settings"];

                          if (alwaysInclude.includes(item.title)) {
                            return true;
                          }

                          if (onlyOtcTrue && item.title === "Transaction") {
                            return true;
                          }

                          // Exclude all items except the default ones when only is_otc is true
                          if (onlyOtcTrue) {
                            return false;
                          }

                          // Default items when all specific parameters are false
                          if (useDefaultItems) {
                            return ["Data", "Payment"].includes(item.title);
                          }

                          // Specific item checks
                          switch (item.title) {
                            case "FX/OTC":
                              return is_otc;
                            case "Data":
                              if (is_identity_only) {
                                item.subNav = item.subNav.filter(subItem => subItem.subTitle === "Identity");
                                return true;
                              }
                              return is_data;
                            case "Payment":
                              return is_payment;
                            default:
                              return false;
                          }
                        }).map((item, index) => (
                          <SideBarMobileMenu 
                            item={item}
                            key={index}
                            isActive={index === activeIndex}
                            onClick={() => handleSubMenuClick(index)}
                            activeItem={activeItem} 
                            setActiveItem={setActiveItem}
                            mode={mode} 
                            MobileMenuHandler={MobileMenuHandler}
                          />
                        ))
                      ) : null // Return nothing when there is no current user
                    }
            </div>
            </div>
        
            {/* profile sidebar */}
            <div className='sidebar-profile'>
            {showNewBusinessModal && <NewBusinessModal />}

            {showLogout && (
                <div className='profile-'>
                <div className='profile-logout' onClick={handleNewBusinessClick}>
                    <img src={process.env.PUBLIC_URL+"/images/briefcase.svg"} alt="nav" />
                    <p className='profile-text'>Your Businesses</p>
                </div>
                {
                (( currentUser?.user?.isLive ===true)) &&
                    <div className='profile-logout' onClick={handleAgreement}>
                        <img src={process.env.PUBLIC_URL+"/images/handshakeNav.svg"} alt="nav" />
                        <p className='profile-text'>Merchant Agreement</p>
                    </div>
                }
                <div className='profile-logout'>
                    <img src={process.env.PUBLIC_URL+"/images/setting-s.svg"} alt="nav" />
                    <p className='profile-text'>Settings</p>
                </div>
                <div className='logout'>
                    <img onClick={handleLogOut} src={process.env.PUBLIC_URL+"/images/logout.svg"} alt="nav" />
                </div>
            </div>
            )}

            {!showLogout && <div className='sidbar-line'></div>}
            <div className='nav-profile'>
                <div className='nav-profile-avatar'>
                      <span className='avater_template'>{currentUser.user.business_name?(`${String(String(currentUser.user.business_name).split(" ")[0]).slice(0,1)}`):"N/A"}</span>
              
                    <img className='nav-profile-online-indicator' src={process.env.PUBLIC_URL+"/images/avataOnlineIndicator.svg"} alt="avater" />
                </div>

                <div style={{display:"flex",justifyContent:"space-between"}} onClick={toggleShowLogout}>
                <div className='nav-profile-content'>
                    {/* <h1 className='nav-heading'>{currentUser.user.business_name}</h1> */}
                    <h1 className='nav-heading'>{(currentUser.user.business_name).length > 13 ?String(currentUser.user.business_name).slice(0, 14) + "..." :currentUser.user.business_name }</h1>

                    <div className='link-right-copy' >
                        {/* <p className='nav-email'>{email}</p>
                        <img src='' alt='' /> */}
                        <div style={{display:"flex", alignItems:"center",gap:"3px"}}>
                        <span className='id_profile'>ID: </span>
                        <input ref={refLink} className='input_link_copy id_profile' type='text' value={currentUser.user.businessId} />
                        </div>
                        <img onClick={copyToClipboard.bind(null,refLink)} src='/images/copy.svg' alt="copy" style={{width:"14px",height:"14px"}}/>
                    </div>
                </div>
                <img className='sidebar-avater' src={showLogout ? "/images/chevron-down.svg" : "/images/chevron-up.svg"} alt="arrow" onClick={toggleShowLogout}/>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default SideBarMobile
