import React, { useRef, useState } from 'react'
import SideBarData  from './SideBarData.json'
import SidebarSubMenu from './SideBarMenu';
import { useDispatch, useSelector } from 'react-redux';
import './Sidebar.scss'
import { logout } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from '../utilities/clipboardUtils';
function SideBar({avatar,name,email, mode,showNewBusinessModal, handleNewBusinessClick}) {

  const {currentUser} = useSelector((state)=>state?.user)
  const allSideBarData = SideBarData;
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [showLogout, setShowLogout] = useState(false);
  // const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);
  const refLink = useRef(null);



  const handleSubMenuClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    setShowLogout(false);

  };
  const handleAgreement = () => {
    navigate('/agreement')
  }
  const handleLogOut = () =>{
    dispatch(logout())
    navigate('/')
  }
  
  const toggleShowLogout = () => {
    setShowLogout(!showLogout);
    // To close the dropdown when the user clicks on the showLogout arrow
    setActiveIndex(null);
    setActiveItem(null);
  };
  const navigateHome = ()=>{
    navigate('/')
  }
  // const handleNewBusinessClick = () => {
  //   setShowNewBusinessModal(true);
  // };
  
  return (
    <div className='sideBar'>
      <div className='sideBarWrapper'>
        <div className='sidebar-menu-wrapper'>
          <img style={{marginTop:"20px"}} onClick={navigateHome} className='sidebar-logo' src={process.env.PUBLIC_URL+"/images/logoSidbar.svg"} alt="nav" />
          <div className='sidebar-content-container'>
              <h1 className='main-menu-text'>MAIN MENU</h1> 
              {/* {
                  allSideBarData.map((item,index)=>(
                      <SidebarSubMenu 
                      item ={item}
                      key={index}
                      isActive={index === activeIndex}
                      onClick={() => handleSubMenuClick(index)}
                      activeItem={activeItem} 
                      setActiveItem={setActiveItem}
                      mode={mode} 
                      />
                  ))
              } */}

              
                {/* {
                  allSideBarData.filter((item,index) => {
                      if (currentUser && currentUser.user.is_otc) {

                          if (item.title === "FX/OTC") {
                              return true;
                          } else if (item.title === "Data") {
                            
                            if (currentUser.user.is_identity_only) {
                              
                            
                              item.subNav = item.subNav.filter(subItem => subItem.subTitle === "Identity");
                              return true;
                            } else {
                                return currentUser.user.is_data;
                            }
                           
                          } else if (item.title === "Payment" && currentUser.user.is_payment) {
                              return true;
                          }else {
                              return item.title === "Balance" || item.title === "Get started" || item.title === "Overview" || item.title === "Settings";
                          }
                      } else if (currentUser && !currentUser.user.is_otc) {
                          return item.title !== "FX/OTC";
                      } else {
                          return true; 
                      }
                  }).map((item, index) => (
                      <SidebarSubMenu 
                          item={item}
                          key={index}
                          isActive={index === activeIndex}
                          onClick={() => handleSubMenuClick(index)}
                          activeItem={activeItem} 
                          setActiveItem={setActiveItem}
                          mode={mode} 
                      />
                  ))
                } */}



                    {
                      currentUser ? (
                        allSideBarData.filter((item, index) => {
                          const { user } = currentUser;

                          // Default to false if any parameter is missing
                          const is_otc = user.is_otc ?? false;
                          const is_data = user.is_data ?? false;
                          const is_identity_only = user.is_identity_only ?? false;
                          const is_payment = user.is_payment ?? false;

                          // Check if only is_otc is true and all others are false
                          const onlyOtcTrue = is_otc && !is_data && !is_identity_only && !is_payment;

                          // Check if all specific user parameters are false or undefined
                          const useDefaultItems = !is_otc && !is_data && !is_identity_only && !is_payment;

                          // Items to always include
                          const alwaysInclude = ["Balance", "Get started", "Overview", "Settings"];

                          if (alwaysInclude.includes(item.title)) {
                            return true;
                          }

                          // Include "Transactions" when onlyOtcTrue is true
                          if (onlyOtcTrue && item.title === "Transaction") {
                            return true;
                          }

                          // Exclude all items except the default ones when only is_otc is true
                          if (onlyOtcTrue) {
                            return false;
                          }
                          // Default items when all specific parameters are false
                          if (useDefaultItems) {
                            return ["Data", "Payment"].includes(item.title);
                          }


                          // Specific item checks
                          switch (item.title) {
                            case "FX/OTC":
                              return is_otc;
                            case "Data":
                              if (is_identity_only) {
                                item.subNav = item.subNav.filter(subItem => subItem.subTitle === "Identity");
                                return true;
                              }
                              return is_data;
                            case "Payment":
                              return is_payment;
                            default:
                              return false;
                          }
                        }).map((item, index) => (
                          <SidebarSubMenu 
                            item={item}
                            key={index}
                            isActive={index === activeIndex}
                            onClick={() => handleSubMenuClick(index)}
                            activeItem={activeItem} 
                            setActiveItem={setActiveItem}
                            mode={mode} 
                            showLogout={showLogout}
                          />
                        ))
                      ) : null // Return nothing when there is no current user
                    }
          </div>  
        </div>
       
        {/* profile sidebar */}
        <div className='sidebar-profile'>
        {/* {showNewBusinessModal && <NewBusinessModal />} */}

          {showLogout && (
             <div className='profile-'>
              <div className='profile-logout' onClick={handleNewBusinessClick}>
                <img src={process.env.PUBLIC_URL+"/images/briefcase.svg"} alt="nav" />
                <p className='profile-text'>Your Businesses</p>
              </div>
              {
              (( currentUser?.user?.isLive ===true)) &&
                  <div className='profile-logout' onClick={handleAgreement}>
                    <img src={process.env.PUBLIC_URL+"/images/handshakeNav.svg"} alt="nav" />
                    <p className='profile-text'>Merchant Agreement</p>
                  </div>
              }
              <div className='profile-logout'>
                <img src={process.env.PUBLIC_URL+"/images/setting-s.svg"} alt="nav" />
                <p className='profile-text'>Settings</p>
              </div>
              <div className='logout'>
                <img onClick={handleLogOut} src={process.env.PUBLIC_URL+"/images/logout.svg"} alt="nav" />
              </div>
           </div>
          )}

        {!showLogout && <div className='sidbar-line'></div>}
        <div className='nav-profile'>
            <div className='nav-profile-avatar'>
                {/* <img className='nav-profile-avatar-img' src={avatar} alt=""/> */}
                <span className='avater_template'>{avatar}</span>
                <img className='nav-profile-online-indicator' src={process.env.PUBLIC_URL+"/images/avataOnlineIndicator.svg"} alt="avater" />
            </div>

            <div style={{display:"flex",justifyContent:"space-between"}} onClick={toggleShowLogout}>
              <div className='nav-profile-content'>
                  <h1 className='nav-heading'>{(name).length > 13 ?String(name).slice(0, 14) + "..." :name }</h1>
                  <div className='link-right-copy' >
                    
                    <div style={{display:"flex", alignItems:"center",gap:"3px"}}>
                      <span className='id_profile'>ID: </span>
                      <input ref={refLink} className='input_link_copy id_profile' type='text' value={email} />
                    </div>
                    <img onClick={copyToClipboard.bind(null,refLink)} src='/images/copy.svg' alt="copy" style={{width:"14px",height:"14px"}}/>
                  </div>
              </div>
              <img className='sidebar-avater' src={showLogout ? "/images/chevron-down.svg" : "/images/chevron-up.svg"} alt="arrow" onClick={toggleShowLogout}/>
            </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar 
